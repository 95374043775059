<template>
  <div class="wrap">
    <div class="banner" :style="{backgroundImage: 'url(' + imgUrl.banner + ')'}">
      <div class="text animated fadeIn">
        <p class="icon"></p>
        <p class="h2">CDR数字病区功能扩展中心与应用系统</p>
      </div>
    </div>
    <div class="content">
      <div class="en t2"></div>
      <div class="inner product">
        <h2>产品<em>介绍</em></h2>
        <div class="details">
          <!-- <div class="img">
            <img src="../../assets/images/emr-text.png"
                 alt="">
          </div> -->
          <div class="text bg">
            <p>数字病区系统在CDR基础上为医院病区打造医护患三方共用共享的软硬件基础平台，就像是现代化病区的机场与高速公路系统</p>
            <p>数字病区系统基于windows、 Linux等全球三大基础操作系统研发具备极强的功能打展与兼容能力</p>
            <p>具备医院未来软件快捷兼容接口</p>
            <p>具备医院未来硬件兼容接口与集成能力</p>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="content bg">
      <div class="en t1"></div>
      <div class="inner product">
        <h2>产品<em>特征</em></h2>
        <div class="list">
          <ul>
            <li>
              <em class="p1"></em>
              <h3>病例跟踪</h3>
            </li>
            <li>
              <em class="p2"></em>
              <h3>医护沟通</h3>
            </li>
            <li>
              <em class="p3"></em>
              <h3>远程会议</h3>
            </li>
            <li>
              <em class="p4"></em>
              <h3>病理分析</h3>
            </li>
            <li>
              <em class="p5"></em>
              <h3>学术支持</h3>
            </li>
            <li>
              <em class="p6"></em>
              <h3>临床医疗</h3>
            </li>
            <li>
              <em class="p7"></em>
              <h3>远程医疗</h3>
            </li>
            <li>
              <em class="p8"></em>
              <h3>医学影像</h3>
            </li>
          </ul>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import getAliyunImg from '../../utils/aliyun'

export default {
  name: 'cdr',
  created () {
    this.imgUrl.banner = getAliyunImg.signatureUrl('ytwd/gw/banner3.png')
  },
  data () {
    return {
      imgUrl: {
        banner: ''
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "./css/style.scss";
.banner {
  // background-image: url("../../assets/images/banner3.png");
  .icon {
    background-image: url("../../assets/images/s11.png");
  }
}
.content {
  .product .details {
    align-items: center;
    justify-content: center;
    .text {
      padding: 75px 30px;
      display: flex;
      height: auto;
      flex-direction: column;
      // align-self: flex-start;
      &.bg {
        background-size: 210px 207px;
      }
      p {
        text-indent: 0;
      }
    }
    .img {
      // height: 535px;
      background: none;
    }
  }
}
</style>
